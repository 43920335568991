import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Plot from 'react-plotly.js';
import { getPlotData, reducer, sendRequest } from '../../utils';
import Loader from '../../Components/Loader';
import Table from '../../Components/Table';
import { TimerStatusContext } from '../../Context';

const getDataForTimersPlot = (timers) => {
  /*
  Helper function to get data for timers plot. This function fill empty days with 0 time spent.
  */
  const data = [];

  timers.forEach((timer, i) => {
    data.push({
      date_only: moment(timer.started_at).clone().toISOString(),
      // date_only: timer.started_at.format('MM/DD/YYYY'),
      spent_time: timer.time_spent,
    });

    // Generate missing data for chart
    const nextTimer = timers[i + 1];
    if (nextTimer) {
      const nextTimerStartTime = nextTimer.started_at;
      let diff = nextTimerStartTime.diff(timer.started_at.clone().startOf('day'), 'days');

      let nextDate = timer.started_at.clone();
      while (diff > 1) {
        nextDate = nextDate.add(1, 'days');

        data.push({
          date_only: nextDate.clone().toISOString(),
          // date_only: nextDate.format('MM/DD/YYYY'),
          spent_time: 0,
        });

        diff -= 1;
      }
    }
  });

  return data;
};

const getPrettyTime = (totalMinutes) => {
  const diff = moment.duration(totalMinutes * 60 * 1000);

  const days = diff.days();
  const hours = (`0${days * 24 + diff.hours()}`).slice(-2);
  const minutes = (`0${diff.minutes()}`).slice(-2);
  const seconds = (`0${diff.seconds()}`).slice(-2);

  return `${hours}:${minutes}:${seconds}`;
};

const TimerTable = ({ timers }) => {
  // Table with the following columns: ID, user ID, date started, duration, date ended
  const columns = [
    {
      Header: 'Timer ID',
      accessor: 'id',
      className: 'center relative',
    },
    {
      Header: 'Date Started',
      accessor: 'started_at',
      className: 'center relative',
    },
    {
      Header: 'Duration',
      accessor: 'time_spent',
      className: 'center relative',
    },
    {
      Header: 'Date Ended',
      accessor: 'ended_at',
      className: 'center relative',
    },
  ];

  const data = timers.map((timer) => ({
    id: timer.id,
    started_at: timer.started_at.format('MM/DD/YYYY h:mm A'),
    time_spent: getPrettyTime(timer.time_spent),
    ended_at: timer.ended_at.format('MM/DD/YYYY h:mm A'),
  }));

  return (
    <Table columns={columns} data={data} />
  );
};

TimerTable.propTypes = {
  timers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      started_at: PropTypes.object.isRequired,
      ended_at: PropTypes.object.isRequired,
      time_spent: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

const SpentTimeSummary = ({ patientId, startDate, endDate, forPrinting = false }) => {
  const [timeSpent, setTimeSpent] = React.useState(0);
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: false, isError: false },
  );
  const [timers, setTimers] = React.useState([]);
  const [type, setType] = React.useState('date');

  // Context to track timer status
  const timerStatus = React.useContext(TimerStatusContext);

  const fetchTimers = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/time_trackers`, 'GET')
      .then((response) => {
        if (response.status === 'success') {
          const timers = response.data.time_trackers
            .filter((t) => t.ended_at)
            .map((t) => ({
              ...t,
              started_at: moment.utc(t.started_at).local(),
              ended_at: moment.utc(t.ended_at).local(),
              time_spent: (moment.utc(t.ended_at) - moment.utc(t.started_at)) / 1000 / 60,
            }))
            .filter((t) => t.started_at >= startDate && t.ended_at <= endDate);

          // Add missed days to data
          const chartData = getDataForTimersPlot(timers);
          const totalTimeSpent = timers.reduce((acc, t) => acc + t.time_spent, 0);

          setTimeSpent(totalTimeSpent);
          setTimers(timers);
          dispatch({ type: 'FETCH_SUCCESS', payload: chartData });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  React.useEffect(() => {
    fetchTimers();

    setType(moment(endDate).diff(moment(startDate), 'days') < 2 ? 'time' : 'date');
  }, [patientId, startDate, endDate, timerStatus]);

  if (state.isLoading) {
    return <Loader />;
  }

  if (state.isError) {
    return <h5 className="text-center pt2 pb2">{state.error}</h5>;
  }

  const plotData = getPlotData({
    rawData: state.data,
    xAttr: 'date_only',
    yAttr: 'spent_time',
    label: 'Time Spent, minutes',
    color: '#2196f3',
    aggregate: true,
    summarize: true,
    withTrend: false,
  });

  return (
    <>
      <h5>
        Time Spent:
        {' '}
        <strong>{getPrettyTime(timeSpent)}</strong>
      </h5>

      {
        state.data.length > 0
          ? (
            <>
              <div style={{ minHeight: '450px' }}>
                <Plot
                  data={plotData}
                  style={{ width: '100%', marginBottom: '2rem' }}
                  layout={{
                    width: forPrinting ? '267mm' : null,
                    margin: {
                      l: 50, r: 50, b: 0, t: 20, pad: 0,
                    },
                    yaxis: {
                      title: 'Time spent, minutes',
                      automargin: true,
                      rangemode: 'nonnegative',
                      range: [0, Math.max(...plotData[0].y) + 10],
                    },
                    xaxis: {
                      title: type === 'time' ? 'Time' : 'Date',
                      automargin: true,
                      // type: 'date',
                      tickformat: type === 'time' ? '%-I:%M %p' : '%m/%d/%Y',
                    },
                    autosize: true,
                    legend: {
                      x: 0, y: -0.15, orientation: 'h',
                    },
                  }}
                  config={{
                    displayModeBar: false,
                    responsive: true,
                  }}
                />
              </div>

              <TimerTable timers={timers} />
            </>
          )
          : <h5 className="text-center pt2 pb2">No data found</h5>
      }
    </>
  );
};

SpentTimeSummary.propTypes = {
  patientId: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  forPrinting: PropTypes.bool,
};

SpentTimeSummary.defaultProps = {
  forPrinting: false,
};

export default SpentTimeSummary;
